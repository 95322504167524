import React from 'react';
import Navbar from '../components/Layouts/Navbar';

import Footer from '../components/Layouts/Footer';

import { NextSeo } from 'next-seo';
import StartseiteMainBanner from '../components/Startseite/StartseiteMainBanner';
import ParallaxMainBanner from '../components/Startseite/StartseiteParallax';
import StartseiteCard from '../components/Startseite/StartseiteCard';
import StartseiteDienstleistung from '../components/Startseite/StartseiteDienstleistung';
import StartseiteFeatures from '../components/Startseite/StartseiteFeatures';
import StartseiteFaq from '../components/Startseite/StartseiteFaq';
import StartseiteCaseStudies from '../components/Startseite/StartseiteCaseStudies';
import ÜberunsMitgliedschaften from '../components/About/ÜberunsMitgliedschaften';


const Index = () => {
    return (
        <>
            <NextSeo
                title="Simplify Data - Ihr starker IT-Partner mit innovativen und modernen Lösungen für jede Situation!" description="Simplify-Data - Ihr starker IT-Partner mit innovativen und modernen Lösungen für jede Situation!"
                canonical="https://simplify-data.de/"
                openGraph={{
                    url: 'https://simplify-data.de/',
                    title: 'Ihr starker IT-Partner mit innovativen und modernen Lösungen für jede Situation!',
                    description: 'Ihr starker IT-Partner mit innovativen und modernen Lösungen für jede Situation!',
                    images: [
                        {
                            url: 'https://simplify-data.de/images/simplify-data.png',
                            width: 1080,
                            height: 1080,
                            alt: 'Ihr starker IT-Partner mit innovativen und modernen Lösungen für jede Situation!',
                            type: 'image/jpeg',
                        },

                    ],
                    siteName: 'Ihr starker IT-Partner mit innovativen und modernen Lösungen für jede Situation!',
                }}
                twitter={{
                    handle: '@handle',
                    site: '@site',
                    cardType: 'summary_large_image',
                }}
            />

            <Navbar />
            {/* Static <StartseiteMainBanner />  */ }
            <ParallaxMainBanner />
            <span className='water-edge' style={{height:100, width:"100%", backgroundColor:"#00011D"}}/>
            
            
            {/* "Our Loving Clients" -> dienstleistungen: aufführung der Namen */}
            <StartseiteDienstleistung />
            
            {/* 3 Auflistungen + Bild */ }
            <StartseiteFeatures />

            {/* "4 Card Eigenshaften" ->  */}
            <StartseiteCard />

            {/* "case studies" ->  */}
            <StartseiteCaseStudies />

            <ÜberunsMitgliedschaften />

            <Footer />
        </>
    );
}

export default Index;